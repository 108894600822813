<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('views.shipment.previewInvoice')"
             @click="previewInvoicePdf()"
  >
    <sd-feather-icons type="navigation" size="16" :stroke="color"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {previewFile} from '@/helpers/previewFile';

export default defineComponent({
  name: 'InvoicePreviewButton',
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const previewInvoicePdf = () => {
      const invoicePdfUrl = props.shipment.invoice.invoicePdf.url;
      previewFile(invoicePdfUrl);
    };
    return {
      previewInvoicePdf
    };
  }
});
</script>

