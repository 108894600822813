<template>
  <a-dropdown v-model:visible="columnsDropdownVisible" :trigger="['click']">
    <a-button @click.prevent>
      {{$t('models.shipment.attributes.visibleColumns')}}
      <template #icon>
        <MenuOutlined />
      </template>
    </a-button>
    <template #overlay>
      <Content>
        <a-menu @click="handleColumnMenuClick">
          <a-checkbox-group :value="dropdownColumnValue" :options="columnOptions"
          @change="(event) => $emit('update:dropdownColumnValue', event)"/>
          <a-menu-divider />
          <a-row :gutter="16" class="mt-10">
            <a-col :xs="12" align="center">
              <a-button @click="handleColumnMenuFilter">
                {{$t('actions.ok')}}
              </a-button>
            </a-col>
            <a-col :xs="12" align="center">
              <a-button @click="resetColumnMenuClick">
                {{$t('actions.reset')}}
              </a-button>
            </a-col>
          </a-row>
        </a-menu>
      </Content>
    </template>
  </a-dropdown>
</template>

<script>
import {ref, defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {MenuOutlined} from '@ant-design/icons-vue';
import {Content} from '@/components/dropdown/dropdown-style.js';

export default defineComponent({
  name: 'VisibleColumnsDropdown',
  components: {
    Content,
    MenuOutlined
  },
  emits: ['update:dropdownColumnValue', 'handleColumnMenuFilter', 'resetColumnMenuClick'],
  props: {
    dropdownColumnValue: VueTypes.array.required,
    columnOptions: VueTypes.array.required
  },
  setup(props, context) {
    const columnsDropdownVisible = ref(false);

    const handleColumnMenuClick = () => {
      columnsDropdownVisible.value = false;
    };
    const handleColumnMenuFilter = () => {
      handleColumnMenuClick();
      context.emit('handleColumnMenuFilter');
    };
    const resetColumnMenuClick = () => {
      handleColumnMenuClick();
      context.emit('resetColumnMenuClick');
    };

    return {
      columnsDropdownVisible,
      handleColumnMenuClick,
      handleColumnMenuFilter,
      resetColumnMenuClick
    };
  }
});
</script>
<style lang="scss" scoped>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}
.ant-dropdown {
  padding: 10px 0 !important;
}
.dropdown-filter {
  margin-bottom: 15px !important;
}
</style>
