<template>
  <pop-over :title="$t('views.confirm.cancelShipment.title')"
            :displayedMessage="$t('views.confirm.cancelShipment.message')"
            :canInput="false"
            @confirm="cancel(shipment)">
    <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.cancelShipment')">
      <sd-feather-icons type="x-circle" size="16" :stroke="color"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {computed, defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';

export default defineComponent({
  name: 'ShipmentCancelButton',
  components: {
    PopOver
  },
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def(undefined)
  },
  setup(_, context) {
    const {state, dispatch} = useStore();
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const cancel = async (shipment) => {
      const action = isBackOffice.value ? dispatch('cancelAffectedShipment', {shipment}) :
        dispatch('cancelShipment', shipment);

      context.emit('setLoading');
      const status = await action;
      context.emit('clearLoading');
      if (status === 200) context.emit('success');
    };
    return {
      cancel
    };
  }
});
</script>
<style scoped>
.dISvTC table tbody tr td span {
  display: inline-flex;
}
</style>
