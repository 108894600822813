<template>
  <sd-button
    v-if="!addAdditionalQuoteMode"
    class="btn-icon"
    type="default"
    shape="circle"
    :tooltip="templateMode ? $t('views.shipmentRequest.submitQuoteWithTemplate') :
    $t('views.shipmentRequest.submitQuote')"
    @click="submitQuote('AgentQuoteForm')"
  >
    <sd-feather-icons :type="templateMode ?'file-text' : 'dollar-sign'" size="16" :stroke="color" />
  </sd-button>
  <sd-button
    v-else
    class="btn-icon"
    type="default"
    shape="circle"
    :disabled="shipment.pendingCancellation"
    :tooltip="shipment.pendingCancellation ? $t('messages.help.shipmentPendingCancellation') :
      $t('views.shipmentQuote.addAdditionalQuote')"
    @click="submitQuote('AgentAdditionalQuoteForm')"
  >
    <sd-feather-icons type="plus" size="16" />
  </sd-button>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'QuoteSubmitButton',
  props: {
    shipment: VueTypes.object.def({}),
    template: VueTypes.bool.def(false),
    additionalQuote: VueTypes.bool.def(false),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const router = useRouter();
    const templateMode = ref(props.template);
    const addAdditionalQuoteMode = ref(props.additionalQuote);

    const submitQuote = (routeName) => {
      router.push({
        name: routeName,
        params: {
          shipmentId: props.shipment._jv.id,
          shipmentRequestId: props.shipment._jv.relationships.shipmentRequest.data.id
        },
        query: {
          template: props.template
        }
      });
    };

    return {
      submitQuote,
      templateMode,
      addAdditionalQuoteMode
    };
  }
});
</script>

