<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.view')"
             @click="view(shipment)">
    <sd-feather-icons type="eye" size="16"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'ShipmentViewButton',
  props: {
    shipment: VueTypes.object.def({}),
    isClient: VueTypes.bool,
    isBackOffice: VueTypes.bool,
    currentTab: VueTypes.string
  },
  setup(props) {
    const router = useRouter();

    const view = (record) => {
      if (props.isBackOffice) {
        router.push({
          name: 'BackOfficeShipmentDetails',
          params: {
            shipmentId: record._jv.id
          },
          query: {
            shipmentRequestId: record.shipmentRequestId,
            currentTab: props.currentTab
          }
        });
      } else {
        router.push({
          name: props.isClient ? 'ClientShipmentDetails' : 'AgentShipmentDetails',
          params: {
            shipmentRequestId: record.shipmentRequestId,
            shipmentId: record._jv.id
          }
        });
      }
    };
    return {
      view
    };
  }
});
</script>
