<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('actions.edit')"
             @click="edit(shipment)">
    <sd-feather-icons type="edit" :size="16"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'ShipmentViewButton',
  props: {
    shipment: VueTypes.object.def({})
  },
  setup() {
    const router = useRouter();

    const edit = (record) => {
      router.push({
        name: 'ClientEditShipment',
        params: {
          shipmentRequestId: record.shipmentRequestId,
          shipmentId: record._jv.id
        }
      });
    };
    return {
      edit
    };
  }
});
</script>
