<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('views.shipmentRequest.viewQuotes')"
             @click="view()"
  >
    <sd-feather-icons type="book-open" size="16" :stroke="color"/>
  </sd-button>
</template>

<script>
import {computed, defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'QuoteViewButton',
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const router = useRouter();
    const {state} = useStore();
    const isClient = computed(() => state.session.userType === 'ClientUser');
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');

    const view = () => {
      let routeName;
      if (isClient.value) {
        routeName = 'ClientShipmentQuotes';
      } else if (isBackOffice.value) {
        routeName = 'BackOfficeShipmentQuotes';
      } else {
        routeName = 'AgentShipmentQuotes';
      }
      router.push({
        name: routeName,
        params: {
          shipmentRequestId: props.shipment.shipmentRequestId,
          shipmentId: props.shipment._jv.id
        }
      });
    };
    return {
      view
    };
  }
});
</script>

