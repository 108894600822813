<template>
  <pop-over :title="$t('views.confirm.reopenShipment.title')"
            :displayedMessage="$t('views.confirm.reopenShipment.message')"
            :canInput="false"
            @confirm="reopen()">
    <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.reopenShipment')">
      <sd-feather-icons type="repeat" :size="16"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver';

export default defineComponent({
  name: 'ShipmentReopenButton',
  components: {PopOver},
  emits: ['success'],
  props: {
    shipment: VueTypes.object.def({})
  },
  setup(props, context) {
    const {dispatch} = useStore();
    const reopen = () => {
      dispatch('reopenShipment', props.shipment._jv.id).then(() => context.emit('success'));
    };
    return {
      reopen
    };
  }
});
</script>
