<template>
  <pop-over :title="$t(isWaitingForApproval ? 'layout.ApproveShipmentRejectionPopover.title' :
              'layout.ShipmentRejectionPopover.title')"
            :reason="reason"
            :requiredInput="true"
            :displayedMessage="$t(isWaitingForApproval ? 'layout.ApproveShipmentRejectionPopover.message' :
              'layout.ShipmentRejectionPopover.message')"
            :confirm-button="isWaitingForApproval ? 'actions.approve' : 'actions.confirm'"
            :cancel-button="isWaitingForApproval ? 'actions.reject' : 'actions.cancel'"
            @confirm="reject(shipment, $event)"
            @cancel="cancel(shipment, $event)">
    <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.reject')">
      <sd-feather-icons type="x-circle" size="16" :stroke="color"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';
import {agentShipmentStatus} from '@/constants';
import {useAbility} from '@casl/vue';

export default defineComponent({
  name: 'ShipmentRejectButton',
  emits: ['success'],
  components: {
    PopOver
  },
  props: {
    shipment: VueTypes.object.def({}),
    reason: VueTypes.string.def(''),
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();
    const isWaitingForApproval = props.shipment.agentShipmentStatus === agentShipmentStatus.REJECTION_WAITING_APPROVAL;

    const {can} = useAbility();
    const reject = (shipment, reason) => {
      if (can('approveRejection', shipment)) {
        dispatch('approveShipmentRejection', {shipment, reason}).then(() => context.emit('success'));
      } else {
        dispatch('rejectShipment', {shipment, reason}).then(() => context.emit('success'));
      }
    };
    const cancel = (shipment, reason) => {
      if (can('cancelRejection', shipment)) {
        dispatch('cancelShipmentRejection', {shipment, reason}).then(() => context.emit('success'));
      }
    };
    return {
      isWaitingForApproval,
      reject,
      cancel
    };
  }
});
</script>
<style scoped>
.dISvTC table tbody tr td span {
  display: inline-flex;
}
</style>
