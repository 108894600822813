import axiosInstance from '@/core/plugins/axios';
import i18n from '@/core/plugins/i18n';
import {displayServerErrorsInToaster} from '@/helpers/toasters';

export const previewFile = (fileUrl) => {
  const previewUrl = `${process.env.VUE_APP_GCE_BASE_URL}/${fileUrl}`;
  window.open(previewUrl, '_blank');
};

export const requestPdf = async (previewLink, params, filename) => {
  const response = await axiosInstance.get(previewLink, {responseType: 'blob', params: params});
  if (response.status === 200) {
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const data = URL.createObjectURL(blob);
    window.open(data, '_blank');
  } else {
    displayServerErrorsInToaster([i18n.global.t('messages.error.filePreview')]);
  }
};
