export const agentShipmentStatusColors = {
  rejected: 'danger',
  rejectionWaitingApproval: 'warning',
  archived: 'danger',
  submitted: 'secondary',
  agentQuotesSubmitted: 'secondary',
  publishToOtherAgentsActionNeeded: 'secondary',
  quoteApproved: 'success',
  cancelled: 'danger',
  awaitingCancellationApproval: 'warning',
  finalDocumentsRequested: 'warning',
  allQuotesRejected: 'danger',
  assignedAgentRejectedShipment: 'agent',
  invoiceGenerated: 'success',
  completed: 'success',
  awaitingCompletionApproval: 'secondary'
};
