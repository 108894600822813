<template>
  <a-button type="primary"
            :tooltip="$t('layout.report.exportShipments')"
            @click="exportShipments()"
            :loading="loading"
  >
    <template #icon>
      <CloudDownloadOutlined :style="{fontSize: '16px'}"/>
    </template>
    {{ $t('layout.report.exportShipments') }}
  </a-button>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';
import {requestXlsx} from '@/helpers/downloadFile';
import {theme} from '@/config/theme/themeVariables';
import moment from 'moment/moment';
import _ from 'lodash';
import {CloudDownloadOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'ExportShipmentsButton',
  props: {
    filtersState: VueTypes.object.def({}),
    visibleColumnValue: VueTypes.object.def({}),
    sortState: VueTypes.object.def({})
  },
  components: {
    CloudDownloadOutlined
  },
  setup(props) {
    const loading = ref(false);
    const exportShipments = async () => {
      loading.value = true;
      const filterParams = {};
      const visibleColumnValue = [];
      const orderParams = {};
      _.forEach(props.filtersState, (field, key) => {
        if (props.filtersState[key] !== undefined) filterParams[_.snakeCase(key)] = props.filtersState[key];
      });
      _.forEach(props.visibleColumnValue, (field) => {
        visibleColumnValue.push(_.snakeCase(field));
      });
      _.forEach(props.sortState, (field, key) => {
        if (props.sortState[key] !== undefined) orderParams[_.snakeCase(key)] = props.sortState[key];
      });
      const params = {
        filter: filterParams,
        visible_columns: visibleColumnValue,
        order: {order_dir: orderParams['direction'], order_by: orderParams['col']}
      };
      await requestXlsx('reports/export_shipments', params, `shipments_export_${moment().format('YYYY-MM-DD HH:mm:ss')}`);
      loading.value = false;
    };
    return {
      theme,
      loading,
      exportShipments
    };
  }
});
</script>

