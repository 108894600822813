<template>
  <sd-button class="btn-icon"
             type="default"
             shape="circle"
             :tooltip="$t('views.shipment.downloadInvoice')"
             @click="downloadInvoicePdf()"
  >
    <sd-feather-icons type="download" size="16" :stroke="color"/>
  </sd-button>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {downloadFile} from '@/helpers/downloadFile';

export default defineComponent({
  name: 'InvoiceDownloadButton',
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props) {
    const downloadInvoicePdf = () => {
      const invoicePdf = props.shipment.invoice.invoicePdf;
      downloadFile(invoicePdf.filename, invoicePdf.url);
    };
    return {
      downloadInvoicePdf
    };
  }
});
</script>

