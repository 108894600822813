<template>
  <a-col :xs="24" class="table-search">
    <a-row :gutter="[10, 0]" type="flex" justify="end" align="middle">
      <a-col class="dropdown-filter" v-if="isBackOffice || AgentAndClientShipments">
        <visible-columns-dropdown
          :dropdownColumnValue="dropdownColumnValue"
          @update:dropdownColumnValue="dropdownColumnValue = $event"
          :columnOptions="columnOptionsPerRole"
          @resetColumnMenuClick="resetColumnMenuClick"
          @handleColumnMenuFilter="handleColumnMenuFilter"
        />
      </a-col>
      <a-col class="dropdown-filter" v-if="isBackOffice">
        <a-dropdown v-model:visible="filterVisible" :trigger="['click']">
          <a-button @click.prevent>
            {{$t('models.shipment.attributes.filterBy')}}
          </a-button>
          <template #overlay>
            <Content>
              <a-menu @click="handleMenuClick">
                <a-checkbox-group v-model:value="dropdownValue" :options="filterOptions" />
                <a-menu-divider />
                <a-row :gutter="16" class="mt-10">
                  <a-col :xs="12" align="center">
                    <a-button @click="handleMenuFilter">
                      {{$t('actions.ok')}}
                    </a-button>
                  </a-col>
                  <a-col :xs="12" align="center">
                    <a-button @click="resetMenuClick">
                      {{$t('actions.reset')}}
                    </a-button>
                  </a-col>
                </a-row>
              </a-menu>
            </Content>
          </template>
        </a-dropdown>
      </a-col>
      <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4" v-if="isBackOffice">
        <a-date-picker
          v-model:value="shipmentDateValue"
          :inputReadOnly="true"
          class="date-search"
          :placeholder="$t('models.shipment.attributes.shipmentDate')"
        />
      </a-col>
      <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4">
        <a-date-picker
          v-model:value="dateValue"
          :inputReadOnly="true"
          class="date-search"
          :placeholder="$t('models.shipment.attributes.quoteDeadline')"
        />
      </a-col>
      <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4" v-if="isBackOffice">
        <a-date-picker
          v-model:value="lastTransactionDateValue"
          :inputReadOnly="true"
          class="date-search"
          :placeholder="$t('models.shipment.attributes.lastTransactionDate')"
        />
      </a-col>
      <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4" v-if="isBackOffice">
        <a-date-picker
          v-model:value="lastCommunicationDateValue"
          :inputReadOnly="true"
          class="date-search"
          :placeholder="$t('models.shipment.attributes.lastCommunicationDate')"
        />
      </a-col>
      <a-col :xs="24" :sm="7" :md="7" :lg="5" :xl="5" :xxl="4">
        <a-input-search v-model:value="searchValue" :placeholder="$t('actions.search')" />
      </a-col>
    </a-row>
  </a-col>

  <a-col :xxl="24" :xl="24" :md="24" :xs="24">
    <div class="about-shipment-request-wrapper">
      <sd-cards :title="showTitle ? $t('layout.shipmentRequest.shipments') : ''" :headless="!showTitle">
        <shipments-table
          :searchValue="searchValue"
          :dateValue="dateValue"
          :shipmentDateValue="shipmentDateValue"
          :lastTransactionDateValue="lastTransactionDateValue"
          :lastCommunicationDateValue="lastCommunicationDateValue"
          :search="search"
          :filterValue="filterValue"
          :visibleColumnValue="visibleColumnValue"
          @searched="search = false"
          :setStatus="setStatus"
          :currentView="currentView"
        />
      </sd-cards>
    </div>
  </a-col>
</template>

<script>
import {
  defineComponent, ref,
  computed
} from 'vue';
import VueTypes from 'vue-types';
import ShipmentsTable from './Table';
import {useI18n} from 'vue-i18n';
import {Content} from '@/components/dropdown/dropdown-style.js';
import {useStore} from 'vuex';
import VisibleColumnsDropdown from '@/components/shared/table/VisibleColumnsDropdown';
import {
  shipmentTableCommonVisibleColumns,
  shipmentTableColumnsPerRole
} from '@/constants';

import _ from 'lodash';
import {useRouter} from 'vue-router';

export default defineComponent({
  name: 'Shipments',
  components: {
    ShipmentsTable,
    Content,
    VisibleColumnsDropdown
  },
  props: {
    showTitle: VueTypes.bool.def(true),
    setStatus: VueTypes.string.def(undefined),
    currentView: VueTypes.string.def('')
  },
  setup() {
    const {t} = useI18n();
    const searchValue = ref('');
    const dateValue = ref(undefined);
    const shipmentDateValue = ref(undefined);
    const lastTransactionDateValue = ref(undefined);
    const lastCommunicationDateValue = ref(undefined);
    const search = ref(false);
    const filterVisible = ref(false);
    const filterValue = ref([]);
    const dropdownValue = ref([]);
    const filterOptions = ref([
      {
        label: t('models.shipment.attributes.quoteApproved'),
        value: 'clientHasApprovedQuote'
      },
      {
        label: t('models.shipment.attributes.quoteRecieved'),
        value: 'numberOfOffersSubmitted'
      },
      {
        label: t('models.shipment.attributes.clientWasInvoiced'),
        value: 'invoiceGenerated'
      },
      {
        label: t('models.shipment.attributes.clientPaymentDone'),
        value: 'clientPaymentDone'
      },
      {
        label: t('models.shipment.attributes.agentPaymentDone'),
        value: 'agentPaymentDone'
      },
      {
        label: t('models.shipment.attributes.assistMe'),
        value: 'assistMe'
      }
    ]);
    const handleMenuClick = () => {
      filterVisible.value = false;
    };
    const handleMenuFilter = () => {
      handleMenuClick();
      filterValue.value = dropdownValue.value;
    };
    const resetMenuClick = () => {
      handleMenuClick();
      filterValue.value = dropdownValue.value = [];
    };
    const {
      state,
      getters,
      dispatch
    } = useStore();
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const isAgent = computed(() => state.session.userType === 'AgentUser');

    const router = useRouter();
    const AgentAndClientShipments = computed(() => router.currentRoute.value.name === 'AgentShipments' ||
                                                  router.currentRoute.value.name === 'ClientShipmentRequests');


    const columnVisible = ref(false);
    const columnVisibleSaved = computed(() => getters.shipmentColumns);

    const availableColumnsPerRole = computed(() => {
      return _.concat(shipmentTableCommonVisibleColumns, shipmentTableColumnsPerRole[state.session.userType]);
    });

    const currentColumns = () => !columnVisibleSaved.value || !AgentAndClientShipments.value ?
      availableColumnsPerRole.value : JSON.parse(columnVisibleSaved.value);

    const dropdownColumnValue = ref(currentColumns());
    const visibleColumnValue = ref(currentColumns());

    const columnOptions = ref([
      {
        label: t('models.shipment.attributes.requestDate'),
        value: 'requestDate'
      },
      {
        label: t('models.shipment.attributes.scope'),
        value: 'scope'
      },
      {
        label: t('models.shipment.attributes.shipmentType'),
        value: 'shipmentType'
      },
      {
        label: t('models.shipment.attributes.clientName'),
        value: 'clientName'
      },
      {
        label: t('models.shipment.attributes.agentName'),
        value: 'agentName'
      },
      {
        label: t('models.shipment.attributes.offersSubmitted'),
        value: 'offersSubmitted'
      },
      {
        label: t('models.shipment.attributes.quoteApproved'),
        value: 'quoteApproved'
      },
      {
        label: t('models.shipment.attributes.quoteRecieved'),
        value: 'quoteRecieved'
      },
      {
        label: t('models.shipment.attributes.clientWasInvoiced'),
        value: 'clientWasInvoiced'
      },
      {
        label: t('models.shipment.attributes.clientPaymentDone'),
        value: 'clientPaymentDone'
      },
      {
        label: t('models.shipment.attributes.agentPaymentDone'),
        value: 'agentPaymentDone'
      },
      {
        label: t('models.shipment.attributes.assistMe'),
        value: 'assistMe'
      },
      {
        label: t('models.shipment.attributes.shippingFrom'),
        value: 'shippingFrom'
      },
      {
        label: t('models.shipment.attributes.destinationCountry'),
        value: 'destinationCountry'
      },
      {
        label: t('models.shipment.attributes.lastTransactionDate'),
        value: 'lastTransactionDate'
      },
      {
        label: t('models.shipment.attributes.lastCommunicationDate'),
        value: 'lastCommunicationDate'
      },
      {
        label: t('models.shipment.attributes.totalValue'),
        value: 'totalValue'
      },
      {
        label: t('models.shipment.attributes.shipmentStatus'),
        value: 'shipmentStatus'
      },
      {
        label: t('models.shipment.attributes.trackingStatus'),
        value: 'trackingStatus'
      },
      {
        label: t('models.shipment.attributes.quoteDeadline'),
        value: 'quoteDeadline'
      }
    ]);

    const columnOptionsPerRole = computed(() => {
      return _.filter(columnOptions.value, ({value}) =>_.includes(availableColumnsPerRole.value, value));
    });
    const handleColumnMenuClick = () => {
      columnVisible.value = false;
    };
    const handleColumnMenuFilter = () => {
      dispatch('setShipmentAndShipmentRequestColumns', {columnName: 'shipmentColumns', values: dropdownColumnValue.value});
      visibleColumnValue.value = dropdownColumnValue.value;
    };
    const resetColumnMenuClick = () => {
      dispatch('setShipmentAndShipmentRequestColumns', {columnName: 'shipmentColumns', values: []});
      visibleColumnValue.value = dropdownColumnValue.value = [];
    };

    return {
      searchValue,
      dateValue,
      shipmentDateValue,
      lastTransactionDateValue,
      lastCommunicationDateValue,
      search,
      filterValue,
      dropdownValue,
      filterVisible,
      filterOptions,
      columnVisible,
      columnOptionsPerRole,
      visibleColumnValue,
      dropdownColumnValue,
      handleMenuClick,
      handleMenuFilter,
      resetMenuClick,
      handleColumnMenuClick,
      handleColumnMenuFilter,
      resetColumnMenuClick,
      isAgent,
      isBackOffice,
      AgentAndClientShipments
    };
  }
});
</script>

<style lang="scss" scoped>
.ant-checkbox-group {
  display: flex;
  flex-direction: column;
  padding: 10px 0px 10px 10px;
}
.ant-dropdown {
  padding: 10px 0 !important;
}
.dropdown-filter {
  margin-bottom: 15px !important;
}
</style>
