<template>
    <pop-over :title="$t(`actions.cancelShipment`)"
              :requiredInput="false"
              :canInput="false"
              :displayedMessage="$t(`actions.cancelShipment`)"
              :confirm-button="'actions.confirm'"
              :cancel-button="'actions.cancel'"
              @confirm="confirm"
    >
      <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.cancelShipment')">
        <sd-feather-icons type="x-circle" size="16" :stroke="color"/>
      </sd-button>
    </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';

export default defineComponent({
  name: 'ShipmentRejectButton',
  emits: ['success'],
  components: {PopOver},
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();

    const confirm = async () => {
      const storeAction = dispatch('cancelAffectedShipment', {shipment: props.shipment, isApproval: true});
      const status = await storeAction;
      if (status === 200) context.emit('success');
    };

    return {
      confirm
    };
  }
});
</script>
<style scoped>
.dISvTC table tbody tr td span {
  display: inline-flex;
}
</style>
