<template>
  <pop-over :title="$t('views.confirm.waitForCompletionApproval.title')"
            :displayedMessage="$t('views.confirm.waitForCompletionApproval.message')"
            :canInput="false"
            @confirm="complete()">
    <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.waitForCompletionApproval')">
      <sd-feather-icons type="check-circle" :size="16"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver';

export default defineComponent({
  name: 'ShipmentCompleteButton',
  emits: ['success'],
  components: {PopOver},
  props: {
    shipment: VueTypes.object.def({}),
    shipmentRequestId: VueTypes.string
  },
  setup(props, context) {
    const {dispatch} = useStore();
    const complete = () => {
      dispatch('moveShipmentToWaitingForCompletionApproval', {shipmentRequest: props.shipmentRequestId, shipment: props.shipment._jv.id}).then(() => context.emit('success'));
    };
    return {
      complete
    };
  }
});
</script>
