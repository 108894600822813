import {
  shipmentQuoteStatuses, shipmentStatuses
} from '@/constants';
import _ from 'lodash';
import {useStore} from 'vuex';
import {useAbility} from '@casl/vue';

export const clientCanApproveQuote = (can, quote) => {
  shipment = quote._jv ? quote._jv.relationships.shipment : {};
  return can('approve', quote) && shipmentValid() && shipmentTrackingStatusEmpty() && quote.submittedToClient && isValidQuote(quote) && !isDocumentRequestedOrSubmittedAfterApproval(quote);
};

export const agentCanApproveQuote = (can, quote) => {
  shipment = quote._jv ? quote._jv.relationships.shipment : {};
  const quoteCondition = can('approve', quote) && (quote.submittedToClient || quote.status === 'waiting_primary_user_approval');
  return quoteCondition && shipmentValid() && shipmentTrackingStatusEmpty();
};

export const canRejectQuote = (can, quote) => {
  if (!quote.additionalQuote) return can('reject', quote) && shipmentNotDelivered(quote) && quote.submittedToClient;
  else return can('reject', quote) && shipmentNotDelivered(quote) && quote.submittedToClient && quote.parentQuoteApproved;
};

export const canModifyQuote = (quote, shipment, skipAbility = false) => {
  const store = useStore();
  const {can} = useAbility();
  const isAgent = store.state.session.userType === 'AgentUser';
  const unmodifiableStatuses = [
    shipmentQuoteStatuses.EXPIRED,
    shipmentQuoteStatuses.REJECTED,
    shipmentQuoteStatuses.ARCHIVED
  ];
  const restrictedShipmentStatuses = [
    shipmentStatuses.AWAITING_COMPLETION_APPROVAL,
    shipmentStatuses.COMPLETED
  ];
  const shipmentEnded = shipment?.trackingStatus?.end && _.includes(restrictedShipmentStatuses, shipment?.status);
  let quoteCondition = !_.includes(unmodifiableStatuses, quote?.status);
  if (!(isAgent && skipAbility)) {
    quoteCondition &&= can('update', quote);
  }
  return !shipmentEnded && quoteCondition;
};

export const canViewDocuments = (can, shipment) => {
  return shipment && can('viewDocuments', shipment);
};

export const canUpdateTrackingStatus = (can, shipment) => {
  return shipment && can('updateTrackingStatus', shipment);
};

let shipment;
const shipmentValid = () => shipment.status !== shipmentStatuses.CANCELLED;
const noQuotesApproved = () => shipment.status !== shipmentStatuses.QUOTE_APPROVED;
const shipmentTrackingStatusEmpty = () => !(!_.isEmpty(shipment.trackingStatus) && shipment.trackingStatus.end);
const shipmentNotDelivered = (quote) => {
  return !(quote._jv.relationships.shipment.trackingStatus && quote._jv.relationships.shipment.trackingStatus.end);
};
const isValidQuote = (quote) => {
  return quote.additionalQuote ? quote.parentQuoteApproved : noQuotesApproved();
};

const isDocumentRequestedOrSubmittedAfterApproval = (quote) => {
  return (quote.status === shipmentQuoteStatuses.DOCUMENTS_REQUESTED ||
    quote.status === shipmentQuoteStatuses.DOCUMENTS_SUBMITTED) && shipment.agentId;
};

