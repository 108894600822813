<template>
  <pop-over :title="$t('views.confirm.publishShipment.title')"
            :displayedMessage="$t('views.confirm.publishShipment.message')"
            @confirm="publish(shipment)"
            :canInput="false">
    <sd-button class="btn-icon" type="default" shape="circle" :tooltip="$t('actions.publish')">
      <sd-feather-icons type="send" size="16" :stroke="color"/>
    </sd-button>
  </pop-over>
</template>

<script>
import {defineComponent} from 'vue';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import PopOver from '@/components/modals/PopOver.vue';

export default defineComponent({
  name: 'ShipmentRepublishButton',
  emits: ['success'],
  components: {
    PopOver
  },
  props: {
    shipment: VueTypes.object.def({}),
    color: VueTypes.string.def('')
  },
  setup(props, context) {
    const {dispatch} = useStore();

    const publish = (shipment) => {
      dispatch('republishShipment', {shipment}).then(() => context.emit('success'));
    };
    return {
      publish
    };
  }
});
</script>
<style scoped>
.dISvTC table tbody tr td span {
  display: inline-flex;
}
</style>
