<template>
  <div>
      <a-row justify="end" :gutter="15">
        <a-col>
          <export-shipments-button v-if="isBackOffice" :visibleColumnValue="visibleColumnValue"
                                  :sortState="sortState" :filtersState="filtersState"/>
        </a-col>
      </a-row>

    <table-style-wrapper>
      <table-wrapper class="table-responsive">
        <a-table
          :columns="visibleColumns"
          :data-source="dataSource ? dataSource.data : []"
          :rowKey="'identifier'"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
        <template #shipmentDate="{record}">
            {{record.createdAt ? moment(record.createdAt).format('DD MMM YYYY') : ''}}
        </template>
        <template #requestDate="{record}">
            {{record.requestDate ? moment(record.requestDate).format('DD MMM YYYY') : ''}}
        </template>
         <template #quoteApproved="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.clientHasApprovedQuote ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
          <template #quoteRecieved="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.numberOfOffersSubmitted ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
          <template #clientWasInvoiced="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.invoiceGenerated ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
          <template #clientPaymentDone="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.clientPaymentDone ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
          <template #agentPaymentDone="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.agentPaymentDone ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
          <template #lastTransactionDate="{record}">
            {{record.updatedAt ? moment(record.updatedAt).format('DD MMM YYYY') : ''}}
          </template>
          <template #lastCommunicationDate="{record}">
            {{record.lastCommunicationDate ? moment(record.lastCommunicationDate).format('DD MMM YYYY') : ''}}
          </template>
          <template #status="{record}">
            <span :class="`status-text ${agentShipmentStatusColors[$_.camelCase(record.status)]}`">
              {{$t(`models.shipment.attributes.statuses.${record.status}`)}}
            </span>
          </template>

          <template #quoteStatus="{record}">
            <span :class="`status-text ${quoteStatusColors[$_.camelCase(record.agentMainQuote.status)]}`">
              {{quoteStatus(record.agentMainQuote.status)}}
            </span>
          </template>

          <template #trackingStatus="{record}">
            <span class="status-text secondary" v-if="canViewTrackingStatus(record)">
              {{record.trackingStatus ? record.trackingStatus.value : ''}}
            </span>
          </template>

          <template #quoteDeadline="{record}">
            {{record.quoteDeadline ? moment(record.quoteDeadline).format('DD MMM YYYY') : ''}}
          </template>

          <template #action="{record}">
            <div class="table-actions">
              <shipment-republish-button
                v-if="isClient && $can('manage', 'shipment') &&
                record.status === shipmentStatuses.PUBLISH_TO_OTHER_AGENTS_ACTION_NEEDED"
                :shipment="record"
                @success="handleTableChange(...[pagination, filtersState, {}])"
                :color="theme['primary-color']"/>
              <invoice-preview-button
                :shipment="record"
                v-if="isInvoiceButtonsVisible(record)"
                :color="theme['primary-color']"
              />
              <invoice-download-button
                :shipment="record"
                v-if="isInvoiceButtonsVisible(record)"
                :color="theme['primary-color']"
              />
              <quote-view-button
                v-if="canViewQuotes(record)"
                :shipment="record"
                :color="theme['secondary-color']"
              />
              <quote-submit-button
                v-if="canSubmitQuote(record) && record.agentTemplate"
                :shipment="record"
                :template="true"
                :color="theme['success-color']"
              />
              <quote-submit-button
                v-if="canSubmitQuote(record)"
                :shipment="record"
                :color="theme['success-color']"
              />
              <quote-submit-button
                v-if="canSubmitAdditionalQuote(record)"
                :shipment="record"
                :additionalQuote="true"
                :color="theme['success-color']"
              />
              <shipment-view-button
                :isBackOffice="isBackOffice"
                :isClient="isClient"
                :shipment="record"
                :currentTab="currentView"
              />
              <shipment-clone-button
                v-if="canCloneShipment(record)"
                :isBackOffice="isBackOffice"
                :shipment="record"
              />
              <shipment-edit-button
                v-if="canManageShipment(record)"
                :shipment="record"
              />
              <shipment-reject-button
                v-if="canRejectShipment(record)"
                :shipment="record"
                :reason="record.rejectionByAgent?.reason"
                @success="handleTableChange(...[pagination, filtersState, {}])"
                :color="theme['danger-color']"
              />
              <shipment-wait-for-completion-approval-button
                v-if="canMoveToCompletionApproval(record)"
                :shipment="record"
                :shipmentRequestId="record._jv.relationships.shipmentRequest.data.id"
                @success="handleTableChange(...[pagination, filtersState, {}])"
              />
              <shipment-reopen-button
                v-if="canReopenShipment(record)"
                :shipment="record"
                @success="handleTableChange(...[pagination, filtersState, {}])"
              />
              <shipment-complete-button
                v-if="canCompleteShipment(record)"
                :shipment="record"
                @success="handleTableChange(...[pagination, filtersState, {}])"
              />
              <shipment-cancel-button
                v-if="canCancelShipment(record)"
                :shipment="record"
                :color="theme['danger-color']"
                @setLoading="tableLoader(true)"
                @clearLoading="tableLoader(false)"
                @success="handleTableChange(...[pagination, filtersState, {}])"
              />
              <shipment-back-office-actions
                v-if="backOfficeActionsVisible(record)"
                :shipment="record"
                :color="theme['warning-color']"
                @success="handleTableChange(...[pagination, filtersState, {}])"
              />
            </div>
          </template>
          <template #assistMe="{record}">
            <div class="table-icons">
              <sdFeatherIcons :type="record.assistMe ? 'check-circle' : 'x-circle'" size="18" />
            </div>
          </template>
        </a-table>
      </table-wrapper>
    </table-style-wrapper>
  </div>
</template>

<script>
import {
  computed, defineComponent, ref, reactive, toRef, watch
} from 'vue';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import InvoicePreviewButton from '@/components/shipments/InvoicePreviewButton';
import InvoiceDownloadButton from '@/components/shipments/InvoiceDownloadButton';
import QuoteViewButton from '@/components/shipments/QuoteViewButton';
import ShipmentViewButton from '@/components/shipments/ShipmentViewButton';
import QuoteSubmitButton from '@/components/shipments/QuoteSubmitButton';
import ShipmentEditButton from '@/components/shipments/ShipmentEditButton';
import ShipmentCompleteButton from '@/components/shipments/ShipmentCompleteButton';
import ShipmentWaitForCompletionApprovalButton from '@/components/shipments/ShipmentWaitForCompletionApprovalButton';
import ShipmentReopenButton from '@/components/shipments/ShipmentReopenButton';
import ShipmentCloneButton from '@/components/shipments/ShipmentCloneButton';
import ShipmentRejectButton from '@/components/shipments/ShipmentRejectButton';
import ShipmentCancelButton from '@/components/shipments/ShipmentCancelButton';
import ShipmentRepublishButton from '@/components/shipments/ShipmentRepublishButton';
import ShipmentBackOfficeActions from '@/components/shipments/ShipmentBackOfficeActions';
import {quoteStatusColors} from '@/helpers/quoteStatusColors';
import {agentShipmentStatusColors} from '@/helpers/agentShipmentStatusColors';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {usePagination} from 'vue-request';
import VueTypes from 'vue-types';
import _ from 'lodash';
import moment from 'moment';

import {
  shipmentStatuses, shipmentQuoteStatuses, agentShipmentStatus
} from '@/constants';
import {theme} from '@/config/theme/themeVariables';
import {canModifyQuote} from '@/helpers/quoteActionsConditions';
import {useAbility} from '@casl/vue';
import ExportShipmentsButton from '@/components/shipments/ExportShipmentsButton';

export default defineComponent({
  components: {
    TableStyleWrapper,
    TableWrapper,
    InvoicePreviewButton,
    InvoiceDownloadButton,
    QuoteViewButton,
    ShipmentViewButton,
    ShipmentRejectButton,
    ShipmentCancelButton,
    ShipmentCloneButton,
    ShipmentEditButton,
    ShipmentCompleteButton,
    ShipmentReopenButton,
    ShipmentRepublishButton,
    ShipmentBackOfficeActions,
    ShipmentWaitForCompletionApprovalButton,
    QuoteSubmitButton,
    ExportShipmentsButton
  },
  props: {
    searchValue: VueTypes.string.def(''),
    dateValue: VueTypes.object.def(undefined),
    shipmentDateValue: VueTypes.object.def(undefined),
    lastTransactionDateValue: VueTypes.object.def(undefined),
    lastCommunicationDateValue: VueTypes.object.def(undefined),
    filterValue: VueTypes.object.def({}),
    visibleColumnValue: VueTypes.object.def([]),
    search: VueTypes.bool.def(false),
    setStatus: VueTypes.string.def(undefined),
    currentView: VueTypes.string.def(undefined),
    shipmentRequestId: VueTypes.oneOfType([VueTypes.string.def(''), VueTypes.number.def(undefined)])
  },
  emits: ['searched'],
  setup(props, context) {
    const {t} = useI18n();
    const {
      state,
      getters,
      dispatch
    } = useStore();
    const router = useRouter();
    const requestId = computed(() => props.shipmentRequestId || router.currentRoute.value.params.shipmentRequestId);

    const isClient = computed(() => state.session.userType === 'ClientUser');
    const isAgent = computed(() => state.session.userType === 'AgentUser');
    const isBackOffice = computed(() => state.session.userType === 'BackOfficeUser');
    const isInvoiceButtonsVisible = (shipment) => {
      return (isClient.value || isBackOffice.value) && shipment.invoice;
    };
    const canMoveToCompletionApproval = (shipment) => {
      return isAgent.value && shipment.status !== shipmentStatuses.AWAITING_COMPLETION_APPROVAL &&
        shipment.trackingStatus?.end;
    };
    const quoteStatus = (status) => status ? t(`models.shipmentQuote.attributes.statuses.${status}`) : '';
    const existInColumnValue = (value) => Object.values(props.visibleColumnValue).includes(value);
    const sortDirections = ref(['ascend', 'descend', 'ascend']);

    const columns = computed(() => [
      {
        title: t('models.shipment.attributes.requestIdentifier'),
        dataIndex: 'shipmentRequestIdentifier',
        visible: true,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.identifier'),
        dataIndex: 'identifier',
        visible: true,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.requestDate'),
        dataIndex: 'requestDate',
        visible: (isAgent.value || isBackOffice.value) && existInColumnValue('requestDate'),
        slots: {customRender: 'requestDate'},
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.shipmentDate'),
        dataIndex: 'createdAt',
        visible: isBackOffice.value,
        slots: {customRender: 'shipmentDate'},
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.scope'),
        dataIndex: 'scope',
        visible: (isAgent.value || isBackOffice.value) && existInColumnValue('scope')
      },
      {
        title: t('models.shipment.attributes.shipmentType'),
        dataIndex: 'shipmentType',
        visible: (isAgent.value || isBackOffice.value) && existInColumnValue('shipmentType'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.clientName'),
        dataIndex: 'clientName',
        visible: (isBackOffice.value || isAgent.value) && existInColumnValue('clientName'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.agentName'),
        dataIndex: 'agentName',
        visible: isBackOffice.value && existInColumnValue('agentName'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.offersSubmitted'),
        dataIndex: 'numberOfOffersSubmitted',
        visible: isBackOffice.value && existInColumnValue('offersSubmitted'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.quoteApproved'),
        dataIndex: 'clientHasApprovedQuote',
        visible: isBackOffice.value && existInColumnValue('quoteApproved'),
        slots: {customRender: 'quoteApproved'}
      },
      {
        title: t('models.shipment.attributes.quoteRecieved'),
        dataIndex: 'quoteRecieved',
        visible: isBackOffice.value && existInColumnValue('quoteRecieved'),
        slots: {customRender: 'quoteRecieved'}
      },
      {
        title: t('models.shipment.attributes.clientWasInvoiced'),
        dataIndex: 'invoiceGenerated',
        visible: isBackOffice.value && existInColumnValue('clientWasInvoiced'),
        slots: {customRender: 'clientWasInvoiced'}
      },
      {
        title: t('models.shipment.attributes.clientPaymentDone'),
        dataIndex: 'clientPaymentDone',
        visible: isBackOffice.value && existInColumnValue('clientPaymentDone'),
        slots: {customRender: 'clientPaymentDone'}
      },
      {
        title: t('models.shipment.attributes.agentPaymentDone'),
        dataIndex: 'agentPaymentDone',
        visible: (isAgent.value || isBackOffice.value) && existInColumnValue('agentPaymentDone'),
        slots: {customRender: 'agentPaymentDone'}
      },
      {
        title: t('models.shipment.attributes.assistMe'),
        dataIndex: 'assistMe',
        visible: isBackOffice.value && existInColumnValue('assistMe'),
        slots: {customRender: 'assistMe'}
      },
      {
        title: t('models.shipment.attributes.shippingFrom'),
        dataIndex: 'shippingFrom',
        visible: existInColumnValue('shippingFrom'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.destinationCountry'),
        dataIndex: 'destinationCountryName',
        visible: existInColumnValue('destinationCountry'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.lastTransactionDate'),
        dataIndex: 'updatedAt',
        visible: isBackOffice.value && existInColumnValue('lastTransactionDate'),
        slots: {customRender: 'lastTransactionDate'},
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.lastCommunicationDate'),
        dataIndex: 'lastCommunicationDate',
        visible: isBackOffice.value && existInColumnValue('lastCommunicationDate'),
        slots: {customRender: 'lastCommunicationDate'},
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.totalValue'),
        dataIndex: 'totalValue',
        visible: existInColumnValue('totalValue'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.quoteDeadline'),
        dataIndex: 'quoteDeadline',
        slots: {customRender: 'quoteDeadline'},
        visible: existInColumnValue('quoteDeadline'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.shipmentStatus'),
        dataIndex: 'status',
        slots: {customRender: 'status'},
        filters: props.setStatus ? undefined : _.map(shipmentStatuses, (value) => {
          return {
            text: t(`models.shipment.attributes.statuses.${value}`),
            value
          };
        }),
        visible: existInColumnValue('shipmentStatus'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('models.shipment.attributes.quoteStatus'),
        dataIndex: 'agentMainQuote.status',
        slots: {customRender: 'quoteStatus'},
        visible: !isClient.value && !isBackOffice.value,
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: t('views.shipmentRequest.trackingStatus'),
        dataIndex: 'trackingStatus',
        slots: {customRender: 'trackingStatus'},
        visible: existInColumnValue('trackingStatus'),
        sorter: true,
        sortDirections: sortDirections.value
      },
      {
        title: '',
        key: 'action',
        slots: {customRender: 'action'},
        visible: true
      }
    ]);

    const visibleColumns = computed(() => columns.value.filter((column) => column.visible));
    const getShipments = (params) => {
      if (isBackOffice.value) {
        return dispatch('getAffectedShipments', {params});
      } else if (isAgent.value && router.currentRoute.value.name === 'AgentShipments' || router.currentRoute.value.name === 'ClientShipmentRequests') {
        return dispatch('getAllShipments', {params: {...params, pagination: true}});
      } else {
        return dispatch('getShipments', {shipmentRequestId: requestId.value, params});
      }
    };

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      totalPage
    } = usePagination(getShipments, {
      formatResult: () => getters.shipments,
      manual: true,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => {
      return ({
        total: getters.shipments?.totalEntries,
        current: current.value,
        pageSize: pageSize.value
      });
    });

    const filtersChanged = ref(false);
    const filtersState = reactive({
      status: undefined,
      search: undefined,
      quoteDeadline: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      lastCommunicationDate: undefined,
      clientHasApprovedQuote: undefined,
      numberOfOffersSubmitted: undefined,
      invoiceGenerated: undefined,
      clientPaymentDone: undefined,
      agentPaymentDone: undefined,
      assistMe: undefined
    });
    const sortState = reactive({
      col: undefined, direction: undefined
    });
    const searchValue = toRef(props, 'searchValue');
    watch(() => [props.search, props.searchValue, props.dateValue, props.shipmentDateValue,
      props.lastTransactionDateValue, props.lastCommunicationDateValue, props.filterValue,
      props.visibleColumnValue], () => {
      filtersChanged.value = true;
      filtersState.search = searchValue.value;
      filtersState.quoteDeadline = props.dateValue ? props.dateValue.format('YYYY-MM-DD') : undefined;
      filtersState.createdAt = props.shipmentDateValue ? props.shipmentDateValue.format('YYYY-MM-DD') : undefined;
      filtersState.updatedAt = props.lastTransactionDateValue ? props.lastTransactionDateValue.format('YYYY-MM-DD') : undefined;
      filtersState.lastCommunicationDate = props.lastCommunicationDateValue ? props.lastCommunicationDateValue.format('YYYY-MM-DD') : undefined;
      filtersState.clientHasApprovedQuote = Object.values(props.filterValue).includes('clientHasApprovedQuote') ? true : undefined;
      filtersState.numberOfOffersSubmitted = Object.values(props.filterValue).includes('numberOfOffersSubmitted') ? true : undefined;
      filtersState.invoiceGenerated = Object.values(props.filterValue).includes('invoiceGenerated') ? true : undefined;
      filtersState.clientPaymentDone = Object.values(props.filterValue).includes('clientPaymentDone') ? true : undefined;
      filtersState.agentPaymentDone = Object.values(props.filterValue).includes('agentPaymentDone') ? true : undefined;
      filtersState.assistMe = Object.values(props.filterValue).includes('assistMe') ? true : undefined;
      if (props.searchValue.length === 0 || props.searchValue.length >= 3) {
        setTimeout(() => {
          handleTableChange(pagination.value, filtersState, {});
          context.emit('searched');
        }, 100);
      }
    });

    const totalPages = ref(totalPage);
    const handleTableChange = (pag, filters, sorter) => {
      const filterParams = {};
      _.forEach(filtersState, (field, key) => {
        filtersState[key] = filters[key] || field;
        filterParams[_.snakeCase(key)] = filtersState[key];
      });
      let pageNumber;
      if (filtersChanged.value) {
        pageNumber = 1;
        filtersChanged.value = false;
      } else {
        pageNumber = pag?.current > totalPages.value ? totalPages.value || 1 : pag?.current;
      }

      if (!_.isEmpty(sorter)) {
        sortState.col = _.snakeCase(sorter.field);
        sortState.direction = sorter.order === 'ascend' ? 'asc' : 'desc';
      }

      run({
        per_page: 10,
        page: pageNumber,
        order_by: sortState.col,
        order_dir: sortState.direction,
        ...filterParams
      });
    };
    const tableLoader = (value) => (loading.value = value);
    watch(requestId, (value) => {
      if (value) {
        handleTableChange({}, {}, {});
      }
    });

    watch(() => props.setStatus, (value) => {
      filtersChanged.value = true;
      handleTableChange({}, {status: value}, {});
    }, {immediate: true});


    const {can} = useAbility();
    const canRejectShipment = (shipment) => {
      if (isClient.value || agentShipmentEnded(shipment)) return false;

      return (can('reject', shipment) && shipment.agentShipmentStatus !== agentShipmentStatus.REJECTION_WAITING_APPROVAL) ||
        can('approveRejection', shipment);
    };

    const canSubmitAdditionalQuote = (shipment) => {
      if (isClient.value || isBackOffice.value) return false;

      const quoteStatuses = [shipmentQuoteStatuses.APPROVED,
        shipmentQuoteStatuses.REVALIDATION_REQUESTED];
      return _.includes(quoteStatuses, shipment.agentMainQuote.status) &&
        canModifyQuote(shipment.agentMainQuote, shipment, true);
    };
    const canSubmitQuote = (shipment) => {
      if (isClient.value || isBackOffice.value) return false;

      const noSubmittion = shipment.agentMainQuote.status === shipmentQuoteStatuses.WAITING_AGENT_RESPONSE;
      const verifiedCompany = state.session.currentUser?._jv?.relationships?.company?.status !== 'cs_verification_required';
      return noSubmittion && verifiedCompany && canModifyQuote(shipment.agentMainQuote, shipment);
    };

    const agentCompletedStatus = (shipmentStatus) => {
      const statuses = [shipmentStatuses.AWAITING_COMPLETION_APPROVAL, shipmentStatuses.COMPLETED];
      return _.includes(statuses, shipmentStatus);
    };

    const agentShipmentEnded = (shipment) => {
      return shipment.trackingStatus?.end && agentCompletedStatus(shipment.status);
    };

    const canViewQuotes = (shipment) => {
      return isClient.value || isBackOffice.value || can('read', shipment?.agentMainQuote);
    };
    const canManageShipment = (shipment) => {
      return isClient.value && can('manage', 'shipment') && !_.includes([shipmentStatuses.CANCELLED, shipmentStatuses.COMPLETED], shipment.status);
    };
    const canCloneShipment = () => {
      return isClient.value && can('manage', 'shipment');
    };

    const canCancelShipment = (shipment) => {
      return (isClient.value && can('cancel', shipment) && shipment.status !== shipmentStatuses.COMPLETED) ||
        (isBackOffice.value && can('cancel', shipment) && props.currentView === 'sanctioned');
    };

    const canCompleteShipment = (shipment) => (isBackOffice.value && can('complete', shipment));
    const canReopenShipment = (shipment) => (isBackOffice.value && can('reopen', shipment));
    const canViewTrackingStatus = (shipment) => {
      const companyId = state.session.currentUser?._jv?.relationships?.company?._jv?.id;
      const shipmentAgentId = shipment.agentId?.toString();

      return companyId === shipmentAgentId && !_.includes(['rejected', 'archived'], shipment.agentMainQuote?.status) ||
       isBackOffice.value || isClient.value;
    };

    const backOfficeActionsVisible = (shipment) => {
      return isBackOffice.value && can('cancel', shipment) && props.currentView === 'cancelled';
    };

    return {
      theme,
      quoteStatusColors,
      agentShipmentStatusColors,
      isInvoiceButtonsVisible,
      isClient,
      isAgent,
      isBackOffice,
      dataSource,
      pagination,
      filtersState,
      loading,
      visibleColumns,
      sortState,
      shipmentStatuses,
      shipmentQuoteStatuses,
      agentShipmentStatus,
      moment,
      canMoveToCompletionApproval,
      tableLoader,
      handleTableChange,
      canRejectShipment,
      canSubmitQuote,
      canViewQuotes,
      canManageShipment,
      canCloneShipment,
      canCancelShipment,
      backOfficeActionsVisible,
      canSubmitAdditionalQuote,
      canCompleteShipment,
      canReopenShipment,
      canViewTrackingStatus,
      quoteStatus
    };
  }
});
</script>
